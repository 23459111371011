// This file was automatically generated from admin.venue.ivvy.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.IvvySettingsEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.IvvySettingsEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.IvvySettingsEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-edit" style="padding: 0 0 18px 18px">', (opt_data.success_message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.success_message) + '</div>' : '', (opt_data.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.error_message) + '</div>' : '', '<form class="ajaxify" id="ivvy_settings_form" method="post" action="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue_id), '/ivvy"><h2>Edit iVvy settings: ', soy.$$escapeHtml(opt_data.content.venue_name), '</h2><div class="right-align"><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue_id), '/edit">Go back to edit Venue</a></div><table style="width: 100%"><tr><td class="form-label">Enable iVvy</td><td>');
  Nightloop.Templates.Admin.EnabledDisabledToggle({field: opt_data.content.form.fields.enabled}, output);
  output.append('</td></tr><tr><td class="form-label">API Key</td><td><input style="width: 300px" name="', soy.$$escapeHtml(opt_data.content.form.fields.api_key.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.api_key.value), '" /></td></tr><tr><td class="form-label">API Secret</td><td><input style="width: 300px" name="', soy.$$escapeHtml(opt_data.content.form.fields.api_secret.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.api_secret.value), '" /></td></tr><tr><td class="form-label">API Environment</td><td><select name="', soy.$$escapeHtml(opt_data.content.form.fields.api_environment.name), '">');
  var choiceList6672 = opt_data.content.API_ENVIRONMENT_CHOICES;
  var choiceListLen6672 = choiceList6672.length;
  for (var choiceIndex6672 = 0; choiceIndex6672 < choiceListLen6672; choiceIndex6672++) {
    var choiceData6672 = choiceList6672[choiceIndex6672];
    output.append('<option value="', soy.$$escapeHtml(choiceData6672[0]), '" ', (choiceData6672[0] == opt_data.content.form.fields.api_environment.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData6672[1]), '</option>');
  }
  output.append('</select></td></tr><tr><td class="form-label"></td><td><button id="test_connection">iVvy Test Connection</button></td></tr><tr><td class="form-label">iVvy Venue ID</td><td><input id="venue_id_input" style="width: 300px" type="number" name="', soy.$$escapeHtml(opt_data.content.form.fields.ivvy_venue_id.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.ivvy_venue_id.value), '" /><select id="venue_id_select" style="width: 300px; display: none;"></select> <button id="venue_id_pull_button">Check API has access to selected venue</button><button id="venue_id_manual_button" style="display: none;">Enter Venue ID manually</button></td></tr><tr><td class="form-label">Import # Days</td><td><input style="width: 300px" type="number" name="', soy.$$escapeHtml(opt_data.content.form.fields.import_num_days.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.import_num_days.value), '" placeholder="365" /><br/><i>Import this many days into the future (defaults to 365 days)</i></td></tr><tr><td class="form-label">Mapping strategy</td><td><label><div><select name="', soy.$$escapeHtml(opt_data.content.form.fields.mapping_strategy.name), '">');
  var choiceList6694 = opt_data.content.MAPPING_STRATEGY_CHOICES;
  var choiceListLen6694 = choiceList6694.length;
  for (var choiceIndex6694 = 0; choiceIndex6694 < choiceListLen6694; choiceIndex6694++) {
    var choiceData6694 = choiceList6694[choiceIndex6694];
    output.append('<option value="', soy.$$escapeHtml(choiceData6694[0]), '" ', (choiceData6694[0] == opt_data.content.form.fields.mapping_strategy.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData6694[1]), '</option>');
  }
  output.append('</select></div> <span id="strategy_description_one_actual_per_booking" class="strategy_description" style="', (opt_data.content.form.fields.mapping_strategy.value != 'one_actual_per_booking') ? 'display: none' : '', '">Only create one Reservation per Ivvy Booking (Combines all Sessions, only supports a single day per Booking)</span><span id="strategy_description_one_actual_per_session" class="strategy_description" style="', (opt_data.content.form.fields.mapping_strategy.value != 'one_actual_per_session') ? 'display: none' : '', '">Create one Reservation per Ivvy Booking <span style="font-weight: bold">Session</span></span></label></td></tr><tr><td class="form-label">Omit Party Size</td><td>');
  Nightloop.Templates.Admin.EnabledDisabledToggle({field: opt_data.content.form.fields.omit_party_size}, output);
  output.append('<br/><i>Do not import party size from iVvy</i></td></tr><tr><td class="form-label">Reservation Tag Assignment</td><td><select name="', soy.$$escapeHtml(opt_data.content.form.fields.reservation_tag_assignment.name), '"><option value="">None</option>');
  var tag_groupList6719 = opt_data.content.reservation_tags;
  var tag_groupListLen6719 = tag_groupList6719.length;
  for (var tag_groupIndex6719 = 0; tag_groupIndex6719 < tag_groupListLen6719; tag_groupIndex6719++) {
    var tag_groupData6719 = tag_groupList6719[tag_groupIndex6719];
    output.append('<optgroup label="', soy.$$escapeHtml(tag_groupData6719.name), '">');
    var tagList6723 = tag_groupData6719.tags;
    var tagListLen6723 = tagList6723.length;
    for (var tagIndex6723 = 0; tagIndex6723 < tagListLen6723; tagIndex6723++) {
      var tagData6723 = tagList6723[tagIndex6723];
      output.append('<option value="', soy.$$escapeHtml(tagData6723.id), '"', (tagData6723.id == opt_data.content.form.fields.reservation_tag_assignment.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(tagData6723.name), '</option>');
    }
    output.append('</optgroup>');
  }
  output.append('</select></td></tr><tr><td class="form-label">Skip Events w/o Tables</td><td>');
  Nightloop.Templates.Admin.EnabledDisabledToggle({field: opt_data.content.form.fields.skip_event}, output);
  output.append('<br/><i>If event does not have mapped SevenRooms tables, then we do not import.<br/>(e.g. same as Tripleseat)</i></td></tr><tr><td class="form-label">Skip Events with Ordering status</td><td>');
  Nightloop.Templates.Admin.EnabledDisabledToggle({field: opt_data.content.form.fields.skip_event_ordering_status}, output);
  output.append('<br/><i>If event current status is Ordering, then we do not import.<br/></i></td></tr><tr><td class="form-label">Skip Events w/o Opportunity</td><td>');
  Nightloop.Templates.Admin.EnabledDisabledToggle({field: opt_data.content.form.fields.skip_event_no_opportunity}, output);
  output.append('<br/><i>If event does not have opportunity, then we do not import.<br/></i></td></tr><tr><td class="form-label">&nbsp;</td><td><input type="submit" value="Save" name="submit"/></td></tr></table></form><script type="text/javascript">\n      $(document).ready(function () {\n          Pmp.Admin.Ivvy.Edit.initialize();\n      });\n      <\/script></div>');
  return opt_sb ? '' : output.toString();
};
